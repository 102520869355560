"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Pharmacy API
 * API for pharmacy management
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.YesNoOption = exports.WeekdaysEnum = exports.TimeRangeType = exports.Role = exports.PolicyType = exports.LocationStatus = exports.InfoCardIcon = exports.DistanceUnits = exports.BusinessType = exports.AmPmEnum = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AmPmEnum = {
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.BusinessType = {
    Llc: 'LLC',
    SCorp: 'S Corp',
    CCorp: 'C Corp',
    Lp: 'LP',
    Llp: 'LLP',
    SoleProprietorship: 'Sole Proprietorship',
    BenefitCorporation: 'Benefit Corporation',
    CloseCorporation: 'Close Corporation',
    NonProfitCorporation: 'Non-Profit Corporation',
    Cooperative: 'Cooperative',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DistanceUnits = {
    Miles: 'miles',
    Kilometers: 'kilometers'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.InfoCardIcon = {
    Bowl: 'bowl',
    Pills: 'pills',
    Bag: 'bag',
    Virus: 'virus',
    VirusShot: 'virus_shot',
    Shot: 'shot',
    Nurse: 'nurse',
    NurseDesk: 'nurse_desk',
    AppHeart: 'app_heart',
    Clipboard: 'clipboard',
    Calendar: 'calendar',
    Box: 'box'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.LocationStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Unknown: 'Unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PolicyType = {
    PrivacyPolicy: 'privacy_policy',
    TermsOfService: 'terms_of_service'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Role = {
    Corporate: 'CORPORATE',
    ItAdmin: 'IT_ADMIN',
    Brand: 'BRAND',
    Manager: 'MANAGER',
    Staff: 'STAFF',
    NoAccess: 'NO_ACCESS',
    LumistryUser: 'LUMISTRY_USER'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TimeRangeType = {
    Normal: 'normal',
    Closure: 'closure',
    Holiday: 'holiday'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.WeekdaysEnum = {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.YesNoOption = {
    True: 'true',
    False: 'false',
    Unknown: 'Unknown'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetServiceOptions: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/tag_categories";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createDepartmentDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentCreate', 'createDepartmentDto', createDepartmentDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createDepartmentDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindAll', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindOne', 'deptId', deptId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursCreate', 'departmentId', departmentId);
                    // verify required parameter 'createNormalTimeRangeDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursCreate', 'createNormalTimeRangeDto', createNormalTimeRangeDto);
                    localVarPath = "/departments/{department_id}/hours"
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createNormalTimeRangeDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursFindAll', 'departmentId', departmentId);
                    localVarPath = "/departments/{department_id}/hours"
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentRemove', 'deptId', deptId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'deptId', deptId);
                    // verify required parameter 'updateDepartmentDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'updateDepartmentDto', updateDepartmentDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateDepartmentDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createExternalReferenceDto' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyCreate', 'createExternalReferenceDto', createExternalReferenceDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/id"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createExternalReferenceDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'externalSource' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyFindOne', 'externalSource', externalSource);
                    // verify required parameter 'externalId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyFindOne', 'externalId', externalId);
                    localVarPath = "/pharmacies/id/{external_source}/{external_id}"
                        .replace("{".concat("external_source", "}"), encodeURIComponent(String(externalSource)))
                        .replace("{".concat("external_id", "}"), encodeURIComponent(String(externalId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationCreate', 'locationId', locationId);
                    // verify required parameter 'createExternalReferenceDto' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationCreate', 'createExternalReferenceDto', createExternalReferenceDto);
                    localVarPath = "/locations/{location_id}/id"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createExternalReferenceDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'externalSource' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationFindOne', 'externalSource', externalSource);
                    // verify required parameter 'externalId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationFindOne', 'externalId', externalId);
                    localVarPath = "/locations/id/{external_source}/{external_id}"
                        .replace("{".concat("external_source", "}"), encodeURIComponent(String(externalSource)))
                        .replace("{".concat("external_id", "}"), encodeURIComponent(String(externalId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateInfoCardDto} createInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsCreate: function (locationId, createInfoCardDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsCreate', 'locationId', locationId);
                    // verify required parameter 'createInfoCardDto' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsCreate', 'createInfoCardDto', createInfoCardDto);
                    localVarPath = "/locations/{location_id}/info_cards"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createInfoCardDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindAll: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/info_cards"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindOne: function (locationId, cardId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsFindOne', 'locationId', locationId);
                    // verify required parameter 'cardId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsFindOne', 'cardId', cardId);
                    localVarPath = "/locations/{location_id}/info_cards/{card_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("card_id", "}"), encodeURIComponent(String(cardId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsRemove: function (locationId, cardId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsRemove', 'locationId', locationId);
                    // verify required parameter 'cardId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsRemove', 'cardId', cardId);
                    localVarPath = "/locations/{location_id}/info_cards/{card_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("card_id", "}"), encodeURIComponent(String(cardId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {UpdateInfoCardDto} updateInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsUpdate: function (locationId, cardId, updateInfoCardDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsUpdate', 'locationId', locationId);
                    // verify required parameter 'cardId' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsUpdate', 'cardId', cardId);
                    // verify required parameter 'updateInfoCardDto' is not null or undefined
                    (0, common_1.assertParamExists)('infoCardsUpdate', 'updateInfoCardDto', updateInfoCardDto);
                    localVarPath = "/locations/{location_id}/info_cards/{card_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("card_id", "}"), encodeURIComponent(String(cardId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateInfoCardDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {FinalizeObWizardDto} finalizeObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFinalize: function (pharmacyId, finalizeObWizardDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('obFinalize', 'pharmacyId', pharmacyId);
                    // verify required parameter 'finalizeObWizardDto' is not null or undefined
                    (0, common_1.assertParamExists)('obFinalize', 'finalizeObWizardDto', finalizeObWizardDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/onboarding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(finalizeObWizardDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFind: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('obFind', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/onboarding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {AutoSaveObWizardDto} autoSaveObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obSave: function (pharmacyId, autoSaveObWizardDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('obSave', 'pharmacyId', pharmacyId);
                    // verify required parameter 'autoSaveObWizardDto' is not null or undefined
                    (0, common_1.assertParamExists)('obSave', 'autoSaveObWizardDto', autoSaveObWizardDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/onboarding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(autoSaveObWizardDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createParentCompanyDto' is not null or undefined
                    (0, common_1.assertParamExists)('parentCompanyCreate', 'createParentCompanyDto', createParentCompanyDto);
                    localVarPath = "/parent_companies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createParentCompanyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'search' is not null or undefined
                    (0, common_1.assertParamExists)('parentCompanySearch', 'search', search);
                    localVarPath = "/parent_companies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (search !== undefined) {
                        localVarQueryParameter['search'] = search;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} [search]
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacistFind: function (userId, search, orderBy, limit, offset, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacistFind', 'userId', userId);
                    localVarPath = "/pharmacists/{user_id}/locations"
                        .replace("{".concat("user_id", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (search !== undefined) {
                        localVarQueryParameter['search'] = search;
                    }
                    if (orderBy !== undefined) {
                        localVarQueryParameter['order_by'] = orderBy;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyAppDto} createPharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppCreate: function (pharmacyId, createPharmacyAppDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createPharmacyAppDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppCreate', 'createPharmacyAppDto', createPharmacyAppDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/app"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyAppDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppFind: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppFind', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/app"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppRemove: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppRemove', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/app"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyAppDto} updatePharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppUpdate: function (pharmacyId, updatePharmacyAppDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updatePharmacyAppDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyAppUpdate', 'updatePharmacyAppDto', updatePharmacyAppDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/app"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyAppDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingCreatePharmacyBranding', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingCreatePharmacyBranding', 'createBrandingDto', createBrandingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingDeletePharmacyBranding', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingFindOne', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingUpdatePharmacyBranding', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updateBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingUpdatePharmacyBranding', 'updateBrandingDto', updateBrandingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createPharmacyDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyCreate', 'createPharmacyDto', createPharmacyDto);
                    localVarPath = "/pharmacies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyDelete', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pharmacies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (idOrSlug, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'idOrSlug' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyFindOne', 'idOrSlug', idOrSlug);
                    localVarPath = "/pharmacies/{id_or_slug}"
                        .replace("{".concat("id_or_slug", "}"), encodeURIComponent(String(idOrSlug)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateClosuresRequestDto} createClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursCreateClosures: function (pharmacyId, createClosuresRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursCreateClosures', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createClosuresRequestDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursCreateClosures', 'createClosuresRequestDto', createClosuresRequestDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/closures"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createClosuresRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursFindAll: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursFindAll', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/closures"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursGetHolidays: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursGetHolidays', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/holidays"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursRemoveClosures: function (pharmacyId, closureId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursRemoveClosures', 'pharmacyId', pharmacyId);
                    // verify required parameter 'closureId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursRemoveClosures', 'closureId', closureId);
                    localVarPath = "/pharmacies/{pharmacy_id}/closures/{closure_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("closure_id", "}"), encodeURIComponent(String(closureId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {Array<ToggleHolidayDto>} toggleHolidayDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursToggleHoliday: function (pharmacyId, toggleHolidayDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursToggleHoliday', 'pharmacyId', pharmacyId);
                    // verify required parameter 'toggleHolidayDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyHoursToggleHoliday', 'toggleHolidayDto', toggleHolidayDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/holidays"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(toggleHolidayDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingCreateLocationBranding', 'locationId', locationId);
                    // verify required parameter 'createBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingCreateLocationBranding', 'createBrandingDto', createBrandingDto);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingDeletePharmacyBranding', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingUpdatePharmacyBranding', 'locationId', locationId);
                    // verify required parameter 'updateBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingUpdatePharmacyBranding', 'updateBrandingDto', updateBrandingDto);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createPharmacyLocationDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationCreatePharmacyLocation', 'createPharmacyLocationDto', createPharmacyLocationDto);
                    localVarPath = "/locations";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyLocationDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationDelete', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [search]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [units]
         * @param {string} [lng]
         * @param {string} [lat]
         * @param {string} [postalCode]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (search, limit, offset, units, lng, lat, postalCode, orderBy, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/locations";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (search !== undefined) {
                        localVarQueryParameter['search'] = search;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    if (units !== undefined) {
                        localVarQueryParameter['units'] = units;
                    }
                    if (lng !== undefined) {
                        localVarQueryParameter['lng'] = lng;
                    }
                    if (lat !== undefined) {
                        localVarQueryParameter['lat'] = lat;
                    }
                    if (postalCode !== undefined) {
                        localVarQueryParameter['postal_code'] = postalCode;
                    }
                    if (orderBy !== undefined) {
                        localVarQueryParameter['order_by'] = orderBy;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'locationId', locationId);
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'departmentId', departmentId);
                    // verify required parameter 'createNormalHoursDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'createNormalHoursDto', createNormalHoursDto);
                    localVarPath = "/locations/{location_id}/departments/{department_id}/hours"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createNormalHoursDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationClosuresRequestDto} createLocationClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreateClosures: function (locationId, createLocationClosuresRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreateClosures', 'locationId', locationId);
                    // verify required parameter 'createLocationClosuresRequestDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreateClosures', 'createLocationClosuresRequestDto', createLocationClosuresRequestDto);
                    localVarPath = "/locations/{location_id}/closures"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createLocationClosuresRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/hours"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAllClosures: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursFindAllClosures', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/closures"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursGetHolidays: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursGetHolidays', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/holidays"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursRemoveClosures: function (locationId, closureId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursRemoveClosures', 'locationId', locationId);
                    // verify required parameter 'closureId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursRemoveClosures', 'closureId', closureId);
                    localVarPath = "/locations/{location_id}/closures/{closure_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("closure_id", "}"), encodeURIComponent(String(closureId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateHolidaysDto} updateHolidaysDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursToggleHoliday: function (locationId, updateHolidaysDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursToggleHoliday', 'locationId', locationId);
                    // verify required parameter 'updateHolidaysDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursToggleHoliday', 'updateHolidaysDto', updateHolidaysDto);
                    localVarPath = "/locations/{location_id}/holidays"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateHolidaysDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageGetLanguages: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationLanguageGetLanguages', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/languages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageUpsertLanguages: function (locationId, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationLanguageUpsertLanguages', 'locationId', locationId);
                    // verify required parameter 'requestBody' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationLanguageUpsertLanguages', 'requestBody', requestBody);
                    localVarPath = "/locations/{location_id}/languages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodGetPickupMethods: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationPickupMethodGetPickupMethods', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/pickup_methods"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} methodId
         * @param {UpdatePickupMethodDto} updatePickupMethodDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodUpdatePickupMethod: function (locationId, methodId, updatePickupMethodDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationPickupMethodUpdatePickupMethod', 'locationId', locationId);
                    // verify required parameter 'methodId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationPickupMethodUpdatePickupMethod', 'methodId', methodId);
                    // verify required parameter 'updatePickupMethodDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationPickupMethodUpdatePickupMethod', 'updatePickupMethodDto', updatePickupMethodDto);
                    localVarPath = "/locations/{location_id}/pickup_methods/{method_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("method_id", "}"), encodeURIComponent(String(methodId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePickupMethodDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationUpdate', 'locationId', locationId);
                    // verify required parameter 'updatePharmacyLocationDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationUpdate', 'updatePharmacyLocationDto', updatePharmacyLocationDto);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyLocationDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updatePharmacyDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyUpdate', 'updatePharmacyDto', updatePharmacyDto);
                    localVarPath = "/pharmacies/{pharmacy_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyWebsiteDto} createPharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteCreate: function (pharmacyId, createPharmacyWebsiteDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createPharmacyWebsiteDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteCreate', 'createPharmacyWebsiteDto', createPharmacyWebsiteDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/website"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyWebsiteDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteFind: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteFind', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/website"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteRemove: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteRemove', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/website"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyWebsiteDto} updatePharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteUpdate: function (pharmacyId, updatePharmacyWebsiteDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updatePharmacyWebsiteDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyWebsiteUpdate', 'updatePharmacyWebsiteDto', updatePharmacyWebsiteDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/website"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyWebsiteDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('policyCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createPolicyDto' is not null or undefined
                    (0, common_1.assertParamExists)('policyCreate', 'createPolicyDto', createPolicyDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/policies"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPolicyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('policyFindForPharmacy', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/policies"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionGetServiceOptions: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('serviceOptionGetServiceOptions', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/service_options"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<UpdateLocationServiceOptionDto>} updateLocationServiceOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionUpdateServiceOptions: function (locationId, updateLocationServiceOptionDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('serviceOptionUpdateServiceOptions', 'locationId', locationId);
                    // verify required parameter 'updateLocationServiceOptionDto' is not null or undefined
                    (0, common_1.assertParamExists)('serviceOptionUpdateServiceOptions', 'updateLocationServiceOptionDto', updateLocationServiceOptionDto);
                    localVarPath = "/locations/{location_id}/service_options"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateLocationServiceOptionDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {SalesforceSyncRequestDto} salesforceSyncRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCreateOrUpdate: function (salesforceSyncRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'salesforceSyncRequestDto' is not null or undefined
                    (0, common_1.assertParamExists)('syncCreateOrUpdate', 'salesforceSyncRequestDto', salesforceSyncRequestDto);
                    localVarPath = "/pharmacies/sync/onboard";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(salesforceSyncRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateTagDto} createTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagCreate: function (pharmacyId, createTagDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('tagCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createTagDto' is not null or undefined
                    (0, common_1.assertParamExists)('tagCreate', 'createTagDto', createTagDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/tags"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createTagDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagDelete: function (pharmacyId, tagId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('tagDelete', 'pharmacyId', pharmacyId);
                    // verify required parameter 'tagId' is not null or undefined
                    (0, common_1.assertParamExists)('tagDelete', 'tagId', tagId);
                    localVarPath = "/pharmacies/{pharmacy_id}/tags/{tag_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("tag_id", "}"), encodeURIComponent(String(tagId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagGet: function (pharmacyId, orderBy, limit, offset, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('tagGet', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/tags"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (orderBy !== undefined) {
                        localVarQueryParameter['order_by'] = orderBy;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {UpdateTagDto} updateTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagUpdate: function (pharmacyId, tagId, updateTagDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('tagUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'tagId' is not null or undefined
                    (0, common_1.assertParamExists)('tagUpdate', 'tagId', tagId);
                    // verify required parameter 'updateTagDto' is not null or undefined
                    (0, common_1.assertParamExists)('tagUpdate', 'updateTagDto', updateTagDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/tags/{tag_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("tag_id", "}"), encodeURIComponent(String(tagId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateTagDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezoneFindAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/timezones";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetServiceOptions: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.categoryGetServiceOptions(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentCreate(pharmacyId, createDepartmentDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentFindAll(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentFindOne(pharmacyId, deptId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentHoursCreate(departmentId, createNormalTimeRangeDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentHoursFindAll(departmentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentRemove(pharmacyId, deptId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyFindOne(externalSource, externalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyLocationFindOne(externalSource, externalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateInfoCardDto} createInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsCreate: function (locationId, createInfoCardDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.infoCardsCreate(locationId, createInfoCardDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindAll: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.infoCardsFindAll(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindOne: function (locationId, cardId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.infoCardsFindOne(locationId, cardId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsRemove: function (locationId, cardId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.infoCardsRemove(locationId, cardId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {UpdateInfoCardDto} updateInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsUpdate: function (locationId, cardId, updateInfoCardDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.infoCardsUpdate(locationId, cardId, updateInfoCardDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {FinalizeObWizardDto} finalizeObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFinalize: function (pharmacyId, finalizeObWizardDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.obFinalize(pharmacyId, finalizeObWizardDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFind: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.obFind(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {AutoSaveObWizardDto} autoSaveObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obSave: function (pharmacyId, autoSaveObWizardDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.obSave(pharmacyId, autoSaveObWizardDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.parentCompanyCreate(createParentCompanyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.parentCompanySearch(search, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} [search]
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacistFind: function (userId, search, orderBy, limit, offset, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacistFind(userId, search, orderBy, limit, offset, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyAppDto} createPharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppCreate: function (pharmacyId, createPharmacyAppDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyAppCreate(pharmacyId, createPharmacyAppDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppFind: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyAppFind(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppRemove: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyAppRemove(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyAppDto} updatePharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppUpdate: function (pharmacyId, updatePharmacyAppDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyAppUpdate(pharmacyId, updatePharmacyAppDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingDeletePharmacyBranding(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingFindOne(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyCreate(createPharmacyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyDelete(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyFindAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (idOrSlug, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyFindOne(idOrSlug, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateClosuresRequestDto} createClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursCreateClosures: function (pharmacyId, createClosuresRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyHoursCreateClosures(pharmacyId, createClosuresRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursFindAll: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyHoursFindAll(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursGetHolidays: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyHoursGetHolidays(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursRemoveClosures: function (pharmacyId, closureId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyHoursRemoveClosures(pharmacyId, closureId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {Array<ToggleHolidayDto>} toggleHolidayDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursToggleHoliday: function (pharmacyId, toggleHolidayDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyHoursToggleHoliday(pharmacyId, toggleHolidayDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingDeletePharmacyBranding(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingFindOne(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationDelete(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [search]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [units]
         * @param {string} [lng]
         * @param {string} [lat]
         * @param {string} [postalCode]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (search, limit, offset, units, lng, lat, postalCode, orderBy, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationFind(search, limit, offset, units, lng, lat, postalCode, orderBy, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationFindOne(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationClosuresRequestDto} createLocationClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreateClosures: function (locationId, createLocationClosuresRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursCreateClosures(locationId, createLocationClosuresRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursFindAll(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAllClosures: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursFindAllClosures(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursGetHolidays: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursGetHolidays(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursRemoveClosures: function (locationId, closureId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursRemoveClosures(locationId, closureId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateHolidaysDto} updateHolidaysDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursToggleHoliday: function (locationId, updateHolidaysDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursToggleHoliday(locationId, updateHolidaysDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageGetLanguages: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationLanguageGetLanguages(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageUpsertLanguages: function (locationId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationLanguageUpsertLanguages(locationId, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodGetPickupMethods: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationPickupMethodGetPickupMethods(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} methodId
         * @param {UpdatePickupMethodDto} updatePickupMethodDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodUpdatePickupMethod: function (locationId, methodId, updatePickupMethodDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationPickupMethodUpdatePickupMethod(locationId, methodId, updatePickupMethodDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyUpdate(pharmacyId, updatePharmacyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyWebsiteDto} createPharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteCreate: function (pharmacyId, createPharmacyWebsiteDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyWebsiteCreate(pharmacyId, createPharmacyWebsiteDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteFind: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyWebsiteFind(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteRemove: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyWebsiteRemove(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyWebsiteDto} updatePharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteUpdate: function (pharmacyId, updatePharmacyWebsiteDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyWebsiteUpdate(pharmacyId, updatePharmacyWebsiteDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.policyCreate(pharmacyId, createPolicyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.policyFindForPharmacy(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionGetServiceOptions: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.serviceOptionGetServiceOptions(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<UpdateLocationServiceOptionDto>} updateLocationServiceOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionUpdateServiceOptions: function (locationId, updateLocationServiceOptionDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.serviceOptionUpdateServiceOptions(locationId, updateLocationServiceOptionDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {SalesforceSyncRequestDto} salesforceSyncRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCreateOrUpdate: function (salesforceSyncRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.syncCreateOrUpdate(salesforceSyncRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateTagDto} createTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagCreate: function (pharmacyId, createTagDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tagCreate(pharmacyId, createTagDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagDelete: function (pharmacyId, tagId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tagDelete(pharmacyId, tagId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagGet: function (pharmacyId, orderBy, limit, offset, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tagGet(pharmacyId, orderBy, limit, offset, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {UpdateTagDto} updateTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagUpdate: function (pharmacyId, tagId, updateTagDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tagUpdate(pharmacyId, tagId, updateTagDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezoneFindAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.timezoneFindAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetServiceOptions: function (options) {
            return localVarFp.categoryGetServiceOptions(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            return localVarFp.departmentCreate(pharmacyId, createDepartmentDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            return localVarFp.departmentFindAll(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            return localVarFp.departmentFindOne(pharmacyId, deptId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            return localVarFp.departmentHoursCreate(departmentId, createNormalTimeRangeDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            return localVarFp.departmentHoursFindAll(departmentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            return localVarFp.departmentRemove(pharmacyId, deptId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            return localVarFp.departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            return localVarFp.externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            return localVarFp.externalReferencePharmacyFindOne(externalSource, externalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            return localVarFp.externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            return localVarFp.externalReferencePharmacyLocationFindOne(externalSource, externalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateInfoCardDto} createInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsCreate: function (locationId, createInfoCardDto, options) {
            return localVarFp.infoCardsCreate(locationId, createInfoCardDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindAll: function (locationId, options) {
            return localVarFp.infoCardsFindAll(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsFindOne: function (locationId, cardId, options) {
            return localVarFp.infoCardsFindOne(locationId, cardId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsRemove: function (locationId, cardId, options) {
            return localVarFp.infoCardsRemove(locationId, cardId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} cardId
         * @param {UpdateInfoCardDto} updateInfoCardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoCardsUpdate: function (locationId, cardId, updateInfoCardDto, options) {
            return localVarFp.infoCardsUpdate(locationId, cardId, updateInfoCardDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {FinalizeObWizardDto} finalizeObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFinalize: function (pharmacyId, finalizeObWizardDto, options) {
            return localVarFp.obFinalize(pharmacyId, finalizeObWizardDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obFind: function (pharmacyId, options) {
            return localVarFp.obFind(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {AutoSaveObWizardDto} autoSaveObWizardDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obSave: function (pharmacyId, autoSaveObWizardDto, options) {
            return localVarFp.obSave(pharmacyId, autoSaveObWizardDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            return localVarFp.parentCompanyCreate(createParentCompanyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            return localVarFp.parentCompanySearch(search, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} userId
         * @param {string} [search]
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacistFind: function (userId, search, orderBy, limit, offset, options) {
            return localVarFp.pharmacistFind(userId, search, orderBy, limit, offset, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyAppDto} createPharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppCreate: function (pharmacyId, createPharmacyAppDto, options) {
            return localVarFp.pharmacyAppCreate(pharmacyId, createPharmacyAppDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppFind: function (pharmacyId, options) {
            return localVarFp.pharmacyAppFind(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppRemove: function (pharmacyId, options) {
            return localVarFp.pharmacyAppRemove(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyAppDto} updatePharmacyAppDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyAppUpdate: function (pharmacyId, updatePharmacyAppDto, options) {
            return localVarFp.pharmacyAppUpdate(pharmacyId, updatePharmacyAppDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            return localVarFp.pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            return localVarFp.pharmacyBrandingDeletePharmacyBranding(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            return localVarFp.pharmacyBrandingFindOne(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            return localVarFp.pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            return localVarFp.pharmacyCreate(createPharmacyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            return localVarFp.pharmacyDelete(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            return localVarFp.pharmacyFindAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} idOrSlug
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (idOrSlug, options) {
            return localVarFp.pharmacyFindOne(idOrSlug, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateClosuresRequestDto} createClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursCreateClosures: function (pharmacyId, createClosuresRequestDto, options) {
            return localVarFp.pharmacyHoursCreateClosures(pharmacyId, createClosuresRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursFindAll: function (pharmacyId, options) {
            return localVarFp.pharmacyHoursFindAll(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursGetHolidays: function (pharmacyId, options) {
            return localVarFp.pharmacyHoursGetHolidays(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursRemoveClosures: function (pharmacyId, closureId, options) {
            return localVarFp.pharmacyHoursRemoveClosures(pharmacyId, closureId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {Array<ToggleHolidayDto>} toggleHolidayDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyHoursToggleHoliday: function (pharmacyId, toggleHolidayDto, options) {
            return localVarFp.pharmacyHoursToggleHoliday(pharmacyId, toggleHolidayDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            return localVarFp.pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            return localVarFp.pharmacyLocationBrandingDeletePharmacyBranding(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            return localVarFp.pharmacyLocationBrandingFindOne(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            return localVarFp.pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            return localVarFp.pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            return localVarFp.pharmacyLocationDelete(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [search]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [units]
         * @param {string} [lng]
         * @param {string} [lat]
         * @param {string} [postalCode]
         * @param {string} [orderBy]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (search, limit, offset, units, lng, lat, postalCode, orderBy, options) {
            return localVarFp.pharmacyLocationFind(search, limit, offset, units, lng, lat, postalCode, orderBy, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            return localVarFp.pharmacyLocationFindOne(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            return localVarFp.pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateLocationClosuresRequestDto} createLocationClosuresRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreateClosures: function (locationId, createLocationClosuresRequestDto, options) {
            return localVarFp.pharmacyLocationHoursCreateClosures(locationId, createLocationClosuresRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            return localVarFp.pharmacyLocationHoursFindAll(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAllClosures: function (locationId, options) {
            return localVarFp.pharmacyLocationHoursFindAllClosures(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursGetHolidays: function (locationId, options) {
            return localVarFp.pharmacyLocationHoursGetHolidays(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} closureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursRemoveClosures: function (locationId, closureId, options) {
            return localVarFp.pharmacyLocationHoursRemoveClosures(locationId, closureId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateHolidaysDto} updateHolidaysDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursToggleHoliday: function (locationId, updateHolidaysDto, options) {
            return localVarFp.pharmacyLocationHoursToggleHoliday(locationId, updateHolidaysDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageGetLanguages: function (locationId, options) {
            return localVarFp.pharmacyLocationLanguageGetLanguages(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationLanguageUpsertLanguages: function (locationId, requestBody, options) {
            return localVarFp.pharmacyLocationLanguageUpsertLanguages(locationId, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodGetPickupMethods: function (locationId, options) {
            return localVarFp.pharmacyLocationPickupMethodGetPickupMethods(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} methodId
         * @param {UpdatePickupMethodDto} updatePickupMethodDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationPickupMethodUpdatePickupMethod: function (locationId, methodId, updatePickupMethodDto, options) {
            return localVarFp.pharmacyLocationPickupMethodUpdatePickupMethod(locationId, methodId, updatePickupMethodDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            return localVarFp.pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            return localVarFp.pharmacyUpdate(pharmacyId, updatePharmacyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePharmacyWebsiteDto} createPharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteCreate: function (pharmacyId, createPharmacyWebsiteDto, options) {
            return localVarFp.pharmacyWebsiteCreate(pharmacyId, createPharmacyWebsiteDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteFind: function (pharmacyId, options) {
            return localVarFp.pharmacyWebsiteFind(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteRemove: function (pharmacyId, options) {
            return localVarFp.pharmacyWebsiteRemove(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyWebsiteDto} updatePharmacyWebsiteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyWebsiteUpdate: function (pharmacyId, updatePharmacyWebsiteDto, options) {
            return localVarFp.pharmacyWebsiteUpdate(pharmacyId, updatePharmacyWebsiteDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            return localVarFp.policyCreate(pharmacyId, createPolicyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            return localVarFp.policyFindForPharmacy(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionGetServiceOptions: function (locationId, options) {
            return localVarFp.serviceOptionGetServiceOptions(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<UpdateLocationServiceOptionDto>} updateLocationServiceOptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceOptionUpdateServiceOptions: function (locationId, updateLocationServiceOptionDto, options) {
            return localVarFp.serviceOptionUpdateServiceOptions(locationId, updateLocationServiceOptionDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {SalesforceSyncRequestDto} salesforceSyncRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCreateOrUpdate: function (salesforceSyncRequestDto, options) {
            return localVarFp.syncCreateOrUpdate(salesforceSyncRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateTagDto} createTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagCreate: function (pharmacyId, createTagDto, options) {
            return localVarFp.tagCreate(pharmacyId, createTagDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagDelete: function (pharmacyId, tagId, options) {
            return localVarFp.tagDelete(pharmacyId, tagId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} [orderBy]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagGet: function (pharmacyId, orderBy, limit, offset, options) {
            return localVarFp.tagGet(pharmacyId, orderBy, limit, offset, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} tagId
         * @param {UpdateTagDto} updateTagDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tagUpdate: function (pharmacyId, tagId, updateTagDto, options) {
            return localVarFp.tagUpdate(pharmacyId, tagId, updateTagDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timezoneFindAll: function (options) {
            return localVarFp.timezoneFindAll(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.categoryGetServiceOptions = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).categoryGetServiceOptions(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateDepartmentDto} createDepartmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentCreate = function (pharmacyId, createDepartmentDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentCreate(pharmacyId, createDepartmentDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentFindAll = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentFindAll(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentFindOne = function (pharmacyId, deptId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentFindOne(pharmacyId, deptId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} departmentId
     * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentHoursCreate = function (departmentId, createNormalTimeRangeDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentHoursCreate(departmentId, createNormalTimeRangeDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} departmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentHoursFindAll = function (departmentId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentHoursFindAll(departmentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentRemove = function (pharmacyId, deptId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentRemove(pharmacyId, deptId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {UpdateDepartmentDto} updateDepartmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentUpdate = function (pharmacyId, deptId, updateDepartmentDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateExternalReferenceDto} createExternalReferenceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyCreate = function (pharmacyId, createExternalReferenceDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} externalSource
     * @param {string} externalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyFindOne = function (externalSource, externalId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyFindOne(externalSource, externalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateExternalReferenceDto} createExternalReferenceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyLocationCreate = function (locationId, createExternalReferenceDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} externalSource
     * @param {string} externalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyLocationFindOne = function (externalSource, externalId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyLocationFindOne(externalSource, externalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateInfoCardDto} createInfoCardDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.infoCardsCreate = function (locationId, createInfoCardDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).infoCardsCreate(locationId, createInfoCardDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.infoCardsFindAll = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).infoCardsFindAll(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.infoCardsFindOne = function (locationId, cardId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).infoCardsFindOne(locationId, cardId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} cardId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.infoCardsRemove = function (locationId, cardId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).infoCardsRemove(locationId, cardId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} cardId
     * @param {UpdateInfoCardDto} updateInfoCardDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.infoCardsUpdate = function (locationId, cardId, updateInfoCardDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).infoCardsUpdate(locationId, cardId, updateInfoCardDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {FinalizeObWizardDto} finalizeObWizardDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.obFinalize = function (pharmacyId, finalizeObWizardDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).obFinalize(pharmacyId, finalizeObWizardDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.obFind = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).obFind(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {AutoSaveObWizardDto} autoSaveObWizardDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.obSave = function (pharmacyId, autoSaveObWizardDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).obSave(pharmacyId, autoSaveObWizardDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateParentCompanyDto} createParentCompanyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.parentCompanyCreate = function (createParentCompanyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).parentCompanyCreate(createParentCompanyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.parentCompanySearch = function (search, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).parentCompanySearch(search, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} userId
     * @param {string} [search]
     * @param {string} [orderBy]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacistFind = function (userId, search, orderBy, limit, offset, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacistFind(userId, search, orderBy, limit, offset, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreatePharmacyAppDto} createPharmacyAppDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyAppCreate = function (pharmacyId, createPharmacyAppDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyAppCreate(pharmacyId, createPharmacyAppDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyAppFind = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyAppFind(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyAppRemove = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyAppRemove(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdatePharmacyAppDto} updatePharmacyAppDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyAppUpdate = function (pharmacyId, updatePharmacyAppDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyAppUpdate(pharmacyId, updatePharmacyAppDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateBrandingDto} createBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingCreatePharmacyBranding = function (pharmacyId, createBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingDeletePharmacyBranding = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingDeletePharmacyBranding(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingFindOne = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingFindOne(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdateBrandingDto} updateBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingUpdatePharmacyBranding = function (pharmacyId, updateBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreatePharmacyDto} createPharmacyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyCreate = function (createPharmacyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyCreate(createPharmacyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyDelete = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyDelete(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyFindAll = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyFindAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} idOrSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyFindOne = function (idOrSlug, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyFindOne(idOrSlug, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateClosuresRequestDto} createClosuresRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyHoursCreateClosures = function (pharmacyId, createClosuresRequestDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyHoursCreateClosures(pharmacyId, createClosuresRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyHoursFindAll = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyHoursFindAll(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyHoursGetHolidays = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyHoursGetHolidays(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} closureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyHoursRemoveClosures = function (pharmacyId, closureId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyHoursRemoveClosures(pharmacyId, closureId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {Array<ToggleHolidayDto>} toggleHolidayDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyHoursToggleHoliday = function (pharmacyId, toggleHolidayDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyHoursToggleHoliday(pharmacyId, toggleHolidayDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateBrandingDto} createBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingCreateLocationBranding = function (locationId, createBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingDeletePharmacyBranding = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingDeletePharmacyBranding(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingFindOne = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingFindOne(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdateBrandingDto} updateBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingUpdatePharmacyBranding = function (locationId, updateBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationCreatePharmacyLocation = function (createPharmacyLocationDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationDelete = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationDelete(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [search]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [units]
     * @param {string} [lng]
     * @param {string} [lat]
     * @param {string} [postalCode]
     * @param {string} [orderBy]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationFind = function (search, limit, offset, units, lng, lat, postalCode, orderBy, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationFind(search, limit, offset, units, lng, lat, postalCode, orderBy, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationFindOne = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationFindOne(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} departmentId
     * @param {CreateNormalHoursDto} createNormalHoursDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursCreate = function (locationId, departmentId, createNormalHoursDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateLocationClosuresRequestDto} createLocationClosuresRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursCreateClosures = function (locationId, createLocationClosuresRequestDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursCreateClosures(locationId, createLocationClosuresRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursFindAll = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursFindAll(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursFindAllClosures = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursFindAllClosures(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursGetHolidays = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursGetHolidays(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} closureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursRemoveClosures = function (locationId, closureId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursRemoveClosures(locationId, closureId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdateHolidaysDto} updateHolidaysDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursToggleHoliday = function (locationId, updateHolidaysDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursToggleHoliday(locationId, updateHolidaysDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationLanguageGetLanguages = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationLanguageGetLanguages(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationLanguageUpsertLanguages = function (locationId, requestBody, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationLanguageUpsertLanguages(locationId, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationPickupMethodGetPickupMethods = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationPickupMethodGetPickupMethods(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} methodId
     * @param {UpdatePickupMethodDto} updatePickupMethodDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationPickupMethodUpdatePickupMethod = function (locationId, methodId, updatePickupMethodDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationPickupMethodUpdatePickupMethod(locationId, methodId, updatePickupMethodDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationUpdate = function (locationId, updatePharmacyLocationDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdatePharmacyDto} updatePharmacyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyUpdate = function (pharmacyId, updatePharmacyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyUpdate(pharmacyId, updatePharmacyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreatePharmacyWebsiteDto} createPharmacyWebsiteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyWebsiteCreate = function (pharmacyId, createPharmacyWebsiteDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyWebsiteCreate(pharmacyId, createPharmacyWebsiteDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyWebsiteFind = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyWebsiteFind(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyWebsiteRemove = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyWebsiteRemove(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdatePharmacyWebsiteDto} updatePharmacyWebsiteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyWebsiteUpdate = function (pharmacyId, updatePharmacyWebsiteDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyWebsiteUpdate(pharmacyId, updatePharmacyWebsiteDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreatePolicyDto} createPolicyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.policyCreate = function (pharmacyId, createPolicyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).policyCreate(pharmacyId, createPolicyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.policyFindForPharmacy = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).policyFindForPharmacy(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.serviceOptionGetServiceOptions = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).serviceOptionGetServiceOptions(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<UpdateLocationServiceOptionDto>} updateLocationServiceOptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.serviceOptionUpdateServiceOptions = function (locationId, updateLocationServiceOptionDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).serviceOptionUpdateServiceOptions(locationId, updateLocationServiceOptionDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {SalesforceSyncRequestDto} salesforceSyncRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.syncCreateOrUpdate = function (salesforceSyncRequestDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).syncCreateOrUpdate(salesforceSyncRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateTagDto} createTagDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tagCreate = function (pharmacyId, createTagDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tagCreate(pharmacyId, createTagDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tagDelete = function (pharmacyId, tagId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tagDelete(pharmacyId, tagId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} [orderBy]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tagGet = function (pharmacyId, orderBy, limit, offset, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tagGet(pharmacyId, orderBy, limit, offset, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} tagId
     * @param {UpdateTagDto} updateTagDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tagUpdate = function (pharmacyId, tagId, updateTagDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tagUpdate(pharmacyId, tagId, updateTagDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.timezoneFindAll = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).timezoneFindAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
