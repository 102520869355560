"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Users-Service-DP2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoAuthApi = exports.NoAuthApiFactory = exports.NoAuthApiFp = exports.NoAuthApiAxiosParamCreator = exports.GeneralApi = exports.GeneralApiFactory = exports.GeneralApiFp = exports.GeneralApiAxiosParamCreator = exports.UserSelectedNotificationType = exports.SsoProvider = exports.ResetPasswordRequestDtoNotificationTypeEnum = exports.PolicyType = exports.NotificationType = exports.Gender = exports.ErrorType = exports.CognitoEventType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.CognitoEventType = {
    CognitoPatientUserPasswordReset: 'CognitoPatientUserPasswordReset',
    CognitoPatientUserEmailVerification: 'CognitoPatientUserEmailVerification'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.ErrorType = {
    ExpiredCodeException: 'ExpiredCodeException',
    CodeMismatchException: 'CodeMismatchException',
    NotAuthorizedException: 'NotAuthorizedException',
    UserNotConfirmedException: 'UserNotConfirmedException',
    NotFound: 'NotFound',
    SyntaxError: 'SyntaxError',
    TypeError: 'TypeError',
    InvalidToken: 'InvalidToken',
    LinkExpired: 'LinkExpired',
    NewPasswordRequired: 'NewPasswordRequired',
    ReusedPassword: 'ReusedPassword'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.Gender = {
    Male: 'male',
    Female: 'female'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.NotificationType = {
    Email: 'email',
    Sms: 'sms',
    None: 'none'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PolicyType = {
    TermsOfService: 'terms_of_service',
    PrivacyPolicy: 'privacy_policy'
};
exports.ResetPasswordRequestDtoNotificationTypeEnum = {
    Email: 'email',
    Sms: 'sms'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SsoProvider = {
    None: 'none',
    Apple: 'apple',
    Google: 'google'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.UserSelectedNotificationType = {
    Email: 'email',
    Sms: 'sms',
    None: 'none'
};
/**
 * GeneralApi - axios parameter creator
 * @export
 */
var GeneralApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get confirmation code history by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationCodeHistoryConfirmationCodeIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('confirmationCodeHistoryConfirmationCodeIdGet', 'id', id);
                    localVarPath = "/confirmation_code_history/confirmation_code/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Create user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {CreatePolicyAcceptanceDto} [createPolicyAcceptanceDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPolicyAcceptance: function (userId, createPolicyAcceptanceDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('createUserPolicyAcceptance', 'userId', userId);
                    localVarPath = "/user/patient/{userId}/policy_acceptance"
                        .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPolicyAcceptanceDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPolicyAcceptance: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    (0, common_1.assertParamExists)('getUserPolicyAcceptance', 'userId', userId);
                    localVarPath = "/user/patient/{userId}/policy_acceptance"
                        .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/account_locked";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserAccountLockedIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/email_verification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserEmailVerificationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/password_changed";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserPasswordChangedIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserPasswordResetIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserRegistrationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyUserPasswordResetIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyUserRegistrationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Verifying email
         * @param {VerifyEmailDto} [verifyEmailDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUserVerifyEmail: function (verifyEmailDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/emailVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(verifyEmailDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/accountVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'pharmacyId', pharmacyId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'email', email);
                    localVarPath = "/user/patient/check_user/{pharmacy_id}/{email}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/confirmPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} email Email of the user
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailGet: function (email, xPharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'email' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientEmailEmailGet', 'email', email);
                            // verify required parameter 'xPharmacyId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientEmailEmailGet', 'xPharmacyId', xPharmacyId);
                            localVarPath = "/user/patient/email/{email}"
                                .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            if (xPharmacyId != null) {
                                localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                            }
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'findByEmailsDto' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientFindByEmailsPost', 'findByEmailsDto', findByEmailsDto);
                    localVarPath = "/user/patient/find_by_emails";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(findByEmailsDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'findByIdsDto' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientFindByIdsPost', 'findByIdsDto', findByIdsDto);
                    localVarPath = "/user/patient/find_by_ids";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(findByIdsDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/patient/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/patient/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLogin, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login/sso";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLoginSSOBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password/change";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {string} patientId
         * @param {ResendPhoneNumberConfimationCodeRequestDto} [resendPhoneNumberConfimationCodeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPatientIdResendPhoneNumberConfirmationCodePost: function (patientId, resendPhoneNumberConfimationCodeRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'patientId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientPatientIdResendPhoneNumberConfirmationCodePost', 'patientId', patientId);
                            localVarPath = "/user/patient/{patient_id}/resendPhoneNumberConfirmationCode"
                                .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resendPhoneNumberConfimationCodeRequestDto, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (verifyPhoneNumberRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/phoneNumberVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(verifyPhoneNumberRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} id patientRecordId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRecordIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientRecordIdGet', 'id', id);
                            localVarPath = "/user/patient/record/{id}"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userRegisterDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resendConfirmationCode";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userVerificationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resetPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/sendPasswordResetLink";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/token/refresh";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(refreshTokenRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientUserIdGet', 'userId', userId);
                            localVarPath = "/user/patient/{userId}"
                                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'userId' is not null or undefined
                            (0, common_1.assertParamExists)('userPatientUserIdPatch', 'userId', userId);
                            localVarPath = "/user/patient/{userId}"
                                .replace("{".concat("userId", "}"), encodeURIComponent(String(userId)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            localVarHeaderParameter['Content-Type'] = 'application/json';
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateUserRequest, localVarRequestOptions, configuration);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the information of the patient user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserInfoGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/user_info";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistEmailEmailGet', 'email', email);
                    localVarPath = "/user/pharmacist/email/{email}"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistEmailEmailResendPost', 'email', email);
                    localVarPath = "/user/pharmacist/email/{email}/resend"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdGet', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyListByIds' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdListPost', 'pharmacyListByIds', pharmacyListByIds);
                    localVarPath = "/user/pharmacist/id_list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyListByIds, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyUserRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPharmacistIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/pharmacist/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacistUserLoginRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password/change";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_confirmation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'setPasswordPharmacistUserRequest' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistPasswordSetPost', 'setPasswordPharmacistUserRequest', setPasswordPharmacistUserRequest);
                    localVarPath = "/user/pharmacist/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registerPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Returns a signed link to redirect to helpjuice
         * @param {string} email String pharmacist email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistSsoHelpjuiceRedirectUrlEmailGet: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'email' is not null or undefined
                            (0, common_1.assertParamExists)('userPharmacistSsoHelpjuiceRedirectUrlEmailGet', 'email', email);
                            localVarPath = "/user/pharmacist/sso/helpjuice/redirect_url/{email}"
                                .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/token/refresh";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(refreshTokenRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistUserInfoGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/user_info";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.GeneralApiAxiosParamCreator = GeneralApiAxiosParamCreator;
/**
 * GeneralApi - functional programming interface
 * @export
 */
var GeneralApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.GeneralApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get confirmation code history by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationCodeHistoryConfirmationCodeIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.confirmationCodeHistoryConfirmationCodeIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Create user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {CreatePolicyAcceptanceDto} [createPolicyAcceptanceDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPolicyAcceptance: function (userId, createPolicyAcceptanceDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createUserPolicyAcceptance(userId, createPolicyAcceptanceDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPolicyAcceptance: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getUserPolicyAcceptance(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheckGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Verifying email
         * @param {VerifyEmailDto} [verifyEmailDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUserVerifyEmail: function (verifyEmailDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.patientUserVerifyEmail(verifyEmailDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientAccountVerificationPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientConfirmPasswordPost(confirmPasswordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} email Email of the user
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailGet: function (email, xPharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientEmailEmailGet(email, xPharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientFindByEmailsPost(findByEmailsDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientFindByIdsPost(findByIdsDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginPost(userLogin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginSsoPost(userLoginSSOBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordChangePost(changePasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordSetPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {string} patientId
         * @param {ResendPhoneNumberConfimationCodeRequestDto} [resendPhoneNumberConfimationCodeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPatientIdResendPhoneNumberConfirmationCodePost: function (patientId, resendPhoneNumberConfimationCodeRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPatientIdResendPhoneNumberConfirmationCodePost(patientId, resendPhoneNumberConfimationCodeRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (verifyPhoneNumberRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPhoneNumberVerificationPost(verifyPhoneNumberRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} id patientRecordId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRecordIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientRecordIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientRegisterPost(userRegisterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResendConfirmationCodePost(userVerificationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResetPasswordPost(resetPasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientSendPasswordResetLinkPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientTokenRefreshPost(refreshTokenRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientUserIdGet(userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientUserIdPatch(userId, updateUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the information of the patient user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserInfoGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientUserInfoGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistEmailEmailGet(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistEmailEmailResendPost(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdListPost(pharmacyListByIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistLoginPost(pharmacistUserLoginRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordChangePost(changePasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordConfirmationPost(confirmPasswordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordResetPost(resetPasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Returns a signed link to redirect to helpjuice
         * @param {string} email String pharmacist email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistSsoHelpjuiceRedirectUrlEmailGet: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistSsoHelpjuiceRedirectUrlEmailGet(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistTokenRefreshPost(refreshTokenRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistUserInfoGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistUserInfoGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.GeneralApiFp = GeneralApiFp;
/**
 * GeneralApi - factory interface
 * @export
 */
var GeneralApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.GeneralApiFp)(configuration);
    return {
        /**
         *
         * @summary Get confirmation code history by id
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationCodeHistoryConfirmationCodeIdGet: function (id, options) {
            return localVarFp.confirmationCodeHistoryConfirmationCodeIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Create user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {CreatePolicyAcceptanceDto} [createPolicyAcceptanceDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPolicyAcceptance: function (userId, createPolicyAcceptanceDto, options) {
            return localVarFp.createUserPolicyAcceptance(userId, createPolicyAcceptanceDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get user Policy Acceptance
         * @param {string} userId String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPolicyAcceptance: function (userId, options) {
            return localVarFp.getUserPolicyAcceptance(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return localVarFp.healthCheckGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Verifying email
         * @param {VerifyEmailDto} [verifyEmailDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patientUserVerifyEmail: function (verifyEmailDto, options) {
            return localVarFp.patientUserVerifyEmail(verifyEmailDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return localVarFp.userPatientAccountVerificationPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return localVarFp.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            return localVarFp.userPatientConfirmPasswordPost(confirmPasswordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} email Email of the user
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailGet: function (email, xPharmacyId, options) {
            return localVarFp.userPatientEmailEmailGet(email, xPharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            return localVarFp.userPatientFindByEmailsPost(findByEmailsDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            return localVarFp.userPatientFindByIdsPost(findByIdsDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPatientIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPatientIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return localVarFp.userPatientLoginPost(userLogin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            return localVarFp.userPatientLoginSsoPost(userLoginSSOBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            return localVarFp.userPatientPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return localVarFp.userPatientPasswordSetPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Sends an SMS with the confirmation code for the phone number.
         * @param {string} patientId
         * @param {ResendPhoneNumberConfimationCodeRequestDto} [resendPhoneNumberConfimationCodeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPatientIdResendPhoneNumberConfirmationCodePost: function (patientId, resendPhoneNumberConfimationCodeRequestDto, options) {
            return localVarFp.userPatientPatientIdResendPhoneNumberConfirmationCodePost(patientId, resendPhoneNumberConfimationCodeRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Verifying phone phoneNumber
         * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPhoneNumberVerificationPost: function (verifyPhoneNumberRequest, options) {
            return localVarFp.userPatientPhoneNumberVerificationPost(verifyPhoneNumberRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} id patientRecordId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRecordIdGet: function (id, options) {
            return localVarFp.userPatientRecordIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return localVarFp.userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            return localVarFp.userPatientResendConfirmationCodePost(userVerificationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            return localVarFp.userPatientResetPasswordPost(resetPasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary sendPasswordResetLink
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientSendPasswordResetLinkPost: function (body, options) {
            return localVarFp.userPatientSendPasswordResetLinkPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return localVarFp.userPatientTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get User Dto
         * @param {string} userId String userId of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdGet: function (userId, options) {
            return localVarFp.userPatientUserIdGet(userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update User Info
         * @param {string} userId String userId of the user to update
         * @param {UpdateUserRequest} [updateUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserIdPatch: function (userId, updateUserRequest, options) {
            return localVarFp.userPatientUserIdPatch(userId, updateUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get the information of the patient user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientUserInfoGet: function (options) {
            return localVarFp.userPatientUserInfoGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            return localVarFp.userPharmacistEmailEmailGet(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            return localVarFp.userPharmacistEmailEmailResendPost(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return localVarFp.userPharmacistIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return localVarFp.userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            return localVarFp.userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return localVarFp.userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            return localVarFp.userPharmacistPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            return localVarFp.userPharmacistPasswordConfirmationPost(confirmPasswordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            return localVarFp.userPharmacistPasswordResetPost(resetPasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return localVarFp.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Returns a signed link to redirect to helpjuice
         * @param {string} email String pharmacist email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistSsoHelpjuiceRedirectUrlEmailGet: function (email, options) {
            return localVarFp.userPharmacistSsoHelpjuiceRedirectUrlEmailGet(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return localVarFp.userPharmacistTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get the information of the pharmacy user from an access token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistUserInfoGet: function (options) {
            return localVarFp.userPharmacistUserInfoGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.GeneralApiFactory = GeneralApiFactory;
/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
var GeneralApi = /** @class */ (function (_super) {
    __extends(GeneralApi, _super);
    function GeneralApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get confirmation code history by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.confirmationCodeHistoryConfirmationCodeIdGet = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).confirmationCodeHistoryConfirmationCodeIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Create user Policy Acceptance
     * @param {string} userId String id of the user
     * @param {CreatePolicyAcceptanceDto} [createPolicyAcceptanceDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.createUserPolicyAcceptance = function (userId, createPolicyAcceptanceDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).createUserPolicyAcceptance(userId, createPolicyAcceptanceDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get user Policy Acceptance
     * @param {string} userId String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.getUserPolicyAcceptance = function (userId, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).getUserPolicyAcceptance(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.healthCheckGet = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).healthCheckGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
     * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPatientAccountLockedPost = function (patientUserAccountLockedIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
     * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPatientEmailVerificationPost = function (patientUserEmailVerificationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
     * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPatientPasswordChangedPost = function (patientUserPasswordChangedIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
     * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPatientPasswordResetPost = function (patientUserPasswordResetIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Emit the event \'PatientUserRegistration\'
     * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPatientRegisterPost = function (patientUserRegistrationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
     * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPharmacistPasswordResetPost = function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Emit the event \'PharmacyUserRegistration\'
     * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.integrationsCognitoUserPharmacistRegisterPost = function (pharmacyUserRegistrationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Verifying email
     * @param {VerifyEmailDto} [verifyEmailDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.patientUserVerifyEmail = function (verifyEmailDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).patientUserVerifyEmail(verifyEmailDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Confirm Registration - Verify Email / sms
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientAccountVerificationPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientAccountVerificationPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Checks if the patient exists in dp1 and dp2
     * @param {string} pharmacyId String pharmacyId of the pharmacist user
     * @param {string} email String email of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientCheckUserPharmacyIdEmailGet = function (pharmacyId, email, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
     * @param {ConfirmPasswordDto} [confirmPasswordDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientConfirmPasswordPost = function (confirmPasswordDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientConfirmPasswordPost(confirmPasswordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get User Dto
     * @param {string} email Email of the user
     * @param {string} xPharmacyId UUID of the pharmacy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientEmailEmailGet = function (email, xPharmacyId, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientEmailEmailGet(email, xPharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting patient users\' information by list of user emails
     * @param {FindByEmailsDto} findByEmailsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientFindByEmailsPost = function (findByEmailsDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientFindByEmailsPost(findByEmailsDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting patient users\' information by list of user ids
     * @param {FindByIdsDto} findByIdsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientFindByIdsPost = function (findByIdsDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientFindByIdsPost(findByIdsDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {UserLogin} [userLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientLoginPost = function (userLogin, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientLoginPost(userLogin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Google SSO Login
     * @param {UserLoginSSOBody} [userLoginSSOBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientLoginSsoPost = function (userLoginSSOBody, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientLoginSsoPost(userLoginSSOBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change Patient User Password
     * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPasswordChangePost = function (changePasswordRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Setting the password and creating the record in database
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPasswordSetPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPasswordSetPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Sends an SMS with the confirmation code for the phone number.
     * @param {string} patientId
     * @param {ResendPhoneNumberConfimationCodeRequestDto} [resendPhoneNumberConfimationCodeRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPatientIdResendPhoneNumberConfirmationCodePost = function (patientId, resendPhoneNumberConfimationCodeRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPatientIdResendPhoneNumberConfirmationCodePost(patientId, resendPhoneNumberConfimationCodeRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Verifying phone phoneNumber
     * @param {VerifyPhoneNumberRequest} [verifyPhoneNumberRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientPhoneNumberVerificationPost = function (verifyPhoneNumberRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientPhoneNumberVerificationPost(verifyPhoneNumberRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get User Dto
     * @param {string} id patientRecordId of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientRecordIdGet = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientRecordIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Registration - Creates a patient user, returns a userId
     * @param {UserRegisterDto} [userRegisterDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientRegisterPost = function (userRegisterDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend Confirmation Code
     * @param {UserVerificationRequestDto} [userVerificationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientResendConfirmationCodePost = function (userVerificationRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientResendConfirmationCodePost(userVerificationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary INIT Reset Password, causes an email with code/link to be sent to user
     * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientResetPasswordPost = function (resetPasswordRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientResetPasswordPost(resetPasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary sendPasswordResetLink
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientSendPasswordResetLinkPost = function (body, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientSendPasswordResetLinkPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Generating new id token and access token using refresh token
     * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientTokenRefreshPost = function (refreshTokenRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get User Dto
     * @param {string} userId String userId of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientUserIdGet = function (userId, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientUserIdGet(userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update User Info
     * @param {string} userId String userId of the user to update
     * @param {UpdateUserRequest} [updateUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientUserIdPatch = function (userId, updateUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientUserIdPatch(userId, updateUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get the information of the patient user from an access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPatientUserInfoGet = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPatientUserInfoGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get the information of the pharmacy user by passing the email
     * @param {string} email String email of the pharmacy user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistEmailEmailGet = function (email, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistEmailEmailGet(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend registration email for pharmacy user
     * @param {string} email Email of the pharmacy user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistEmailEmailResendPost = function (email, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistEmailEmailResendPost(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Pharmacist User Info
     * @param {string} id String userId of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdGet = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting pharmacist users\' information by list of user ids
     * @param {PharmacyListByIds} pharmacyListByIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdListPost = function (pharmacyListByIds, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Edit pharmacist user details
     * @param {string} id String userId of the pharmacist user
     * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdPatch = function (id, updatePharmacyUserRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistLoginPost = function (pharmacistUserLoginRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change Pharmacist User Password With Password
     * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordChangePost = function (changePasswordRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password with confirmationCode too
     * @param {ConfirmPasswordDto} [confirmPasswordDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordConfirmationPost = function (confirmPasswordDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordConfirmationPost(confirmPasswordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Reset the pharmacist user\'s password
     * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordResetPost = function (resetPasswordRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordResetPost(resetPasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password
     * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistPasswordSetPost = function (setPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Register the pharmacist user
     * @param {boolean} [email]
     * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistRegisterPost = function (email, registerPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Returns a signed link to redirect to helpjuice
     * @param {string} email String pharmacist email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistSsoHelpjuiceRedirectUrlEmailGet = function (email, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistSsoHelpjuiceRedirectUrlEmailGet(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Generating new id token and access token using refresh token
     * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistTokenRefreshPost = function (refreshTokenRequestDto, options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get the information of the pharmacy user from an access token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    GeneralApi.prototype.userPharmacistUserInfoGet = function (options) {
        var _this = this;
        return (0, exports.GeneralApiFp)(this.configuration).userPharmacistUserInfoGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return GeneralApi;
}(base_1.BaseAPI));
exports.GeneralApi = GeneralApi;
/**
 * NoAuthApi - axios parameter creator
 * @export
 */
var NoAuthApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/account_locked";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserAccountLockedIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/email_verification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserEmailVerificationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/password_changed";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserPasswordChangedIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserPasswordResetIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(patientUserRegistrationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyUserPasswordResetIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/cognito/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyUserRegistrationIntegrationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/accountVerification";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'pharmacyId', pharmacyId);
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientCheckUserPharmacyIdEmailGet', 'email', email);
                    localVarPath = "/user/patient/check_user/{pharmacy_id}/{email}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/confirmPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Send notification(email/sms) when the account has been locked
         * @param {string} email Email of the patient user
         * @param {NotificationType} notificationType
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailLockedAccountPost: function (email, notificationType, xPharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientEmailEmailLockedAccountPost', 'email', email);
                    // verify required parameter 'notificationType' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientEmailEmailLockedAccountPost', 'notificationType', notificationType);
                    // verify required parameter 'xPharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientEmailEmailLockedAccountPost', 'xPharmacyId', xPharmacyId);
                    localVarPath = "/user/patient/email/{email}/locked_account"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (notificationType !== undefined) {
                        localVarQueryParameter['notification_type'] = notificationType;
                    }
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'findByEmailsDto' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientFindByEmailsPost', 'findByEmailsDto', findByEmailsDto);
                    localVarPath = "/user/patient/find_by_emails";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(findByEmailsDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'findByIdsDto' is not null or undefined
                    (0, common_1.assertParamExists)('userPatientFindByIdsPost', 'findByIdsDto', findByIdsDto);
                    localVarPath = "/user/patient/find_by_ids";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(findByIdsDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLogin, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/login/sso";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userLoginSSOBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password/change";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userRegisterDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resendConfirmationCode";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(userVerificationRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/resetPassword";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/patient/token/refresh";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(refreshTokenRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistEmailEmailGet', 'email', email);
                    localVarPath = "/user/pharmacist/email/{email}"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'email' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistEmailEmailResendPost', 'email', email);
                    localVarPath = "/user/pharmacist/email/{email}/resend"
                        .replace("{".concat("email", "}"), encodeURIComponent(String(email)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdGet', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyListByIds' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdListPost', 'pharmacyListByIds', pharmacyListByIds);
                    localVarPath = "/user/pharmacist/id_list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacyListByIds, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyUserRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistIdPrivacyAndPolicyPatch', 'id', id);
                    localVarPath = "/user/pharmacist/{id}/privacy_and_policy"
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // verify required parameter 'id' is not null or undefined
                            (0, common_1.assertParamExists)('userPharmacistIdTermsAndConditionPatch', 'id', id);
                            localVarPath = "/user/pharmacist/{id}/terms_and_condition"
                                .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                            localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                            if (configuration) {
                                baseOptions = configuration.baseOptions;
                            }
                            localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                            localVarHeaderParameter = {};
                            localVarQueryParameter = {};
                            // authentication bearerAuth required
                            // http bearer authentication required
                            return [4 /*yield*/, (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration)];
                        case 1:
                            // authentication bearerAuth required
                            // http bearer authentication required
                            _a.sent();
                            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                            headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                            localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                            return [2 /*return*/, {
                                    url: (0, common_1.toPathString)(localVarUrlObj),
                                    options: localVarRequestOptions,
                                }];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/login";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(pharmacistUserLoginRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password/change";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(changePasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_confirmation";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(confirmPasswordDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/password_reset";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(resetPasswordRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'setPasswordPharmacistUserRequest' is not null or undefined
                    (0, common_1.assertParamExists)('userPharmacistPasswordSetPost', 'setPasswordPharmacistUserRequest', setPasswordPharmacistUserRequest);
                    localVarPath = "/user/pharmacist/password_set";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(setPasswordPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (email !== undefined) {
                        localVarQueryParameter['email'] = email;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(registerPharmacistUserRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/user/pharmacist/token/refresh";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(refreshTokenRequestDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.NoAuthApiAxiosParamCreator = NoAuthApiAxiosParamCreator;
/**
 * NoAuthApi - functional programming interface
 * @export
 */
var NoAuthApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.NoAuthApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheckGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientAccountVerificationPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientConfirmPasswordPost(confirmPasswordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Send notification(email/sms) when the account has been locked
         * @param {string} email Email of the patient user
         * @param {NotificationType} notificationType
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailLockedAccountPost: function (email, notificationType, xPharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientEmailEmailLockedAccountPost(email, notificationType, xPharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientFindByEmailsPost(findByEmailsDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientFindByIdsPost(findByIdsDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginPost(userLogin, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientLoginSsoPost(userLoginSSOBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordChangePost(changePasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientPasswordSetPost(body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientRegisterPost(userRegisterDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResendConfirmationCodePost(userVerificationRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientResetPasswordPost(resetPasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPatientTokenRefreshPost(refreshTokenRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistEmailEmailGet(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistEmailEmailResendPost(email, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdGet(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdListPost(pharmacyListByIds, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdPrivacyAndPolicyPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistIdTermsAndConditionPatch(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistLoginPost(pharmacistUserLoginRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordChangePost(changePasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordConfirmationPost(confirmPasswordDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordResetPost(resetPasswordRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.userPharmacistTokenRefreshPost(refreshTokenRequestDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.NoAuthApiFp = NoAuthApiFp;
/**
 * NoAuthApi - factory interface
 * @export
 */
var NoAuthApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.NoAuthApiFp)(configuration);
    return {
        /**
         *
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return localVarFp.healthCheckGet(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
         * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientAccountLockedPost: function (patientUserAccountLockedIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
         * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientEmailVerificationPost: function (patientUserEmailVerificationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
         * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordChangedPost: function (patientUserPasswordChangedIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
         * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientPasswordResetPost: function (patientUserPasswordResetIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Emit the event \'PatientUserRegistration\'
         * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPatientRegisterPost: function (patientUserRegistrationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
         * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistPasswordResetPost: function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Emit the event \'PharmacyUserRegistration\'
         * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsCognitoUserPharmacistRegisterPost: function (pharmacyUserRegistrationIntegrationRequestDto, options) {
            return localVarFp.integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Confirm Registration - Verify Email / sms
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientAccountVerificationPost: function (body, options) {
            return localVarFp.userPatientAccountVerificationPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Checks if the patient exists in dp1 and dp2
         * @param {string} pharmacyId String pharmacyId of the pharmacist user
         * @param {string} email String email of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientCheckUserPharmacyIdEmailGet: function (pharmacyId, email, options) {
            return localVarFp.userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientConfirmPasswordPost: function (confirmPasswordDto, options) {
            return localVarFp.userPatientConfirmPasswordPost(confirmPasswordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Send notification(email/sms) when the account has been locked
         * @param {string} email Email of the patient user
         * @param {NotificationType} notificationType
         * @param {string} xPharmacyId UUID of the pharmacy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientEmailEmailLockedAccountPost: function (email, notificationType, xPharmacyId, options) {
            return localVarFp.userPatientEmailEmailLockedAccountPost(email, notificationType, xPharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user emails
         * @param {FindByEmailsDto} findByEmailsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByEmailsPost: function (findByEmailsDto, options) {
            return localVarFp.userPatientFindByEmailsPost(findByEmailsDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting patient users\' information by list of user ids
         * @param {FindByIdsDto} findByIdsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientFindByIdsPost: function (findByIdsDto, options) {
            return localVarFp.userPatientFindByIdsPost(findByIdsDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {UserLogin} [userLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginPost: function (userLogin, options) {
            return localVarFp.userPatientLoginPost(userLogin, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Google SSO Login
         * @param {UserLoginSSOBody} [userLoginSSOBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientLoginSsoPost: function (userLoginSSOBody, options) {
            return localVarFp.userPatientLoginSsoPost(userLoginSSOBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change Patient User Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordChangePost: function (changePasswordRequestDto, options) {
            return localVarFp.userPatientPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Setting the password and creating the record in database
         * @param {object} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientPasswordSetPost: function (body, options) {
            return localVarFp.userPatientPasswordSetPost(body, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Registration - Creates a patient user, returns a userId
         * @param {UserRegisterDto} [userRegisterDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientRegisterPost: function (userRegisterDto, options) {
            return localVarFp.userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend Confirmation Code
         * @param {UserVerificationRequestDto} [userVerificationRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResendConfirmationCodePost: function (userVerificationRequestDto, options) {
            return localVarFp.userPatientResendConfirmationCodePost(userVerificationRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary INIT Reset Password, causes an email with code/link to be sent to user
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientResetPasswordPost: function (resetPasswordRequestDto, options) {
            return localVarFp.userPatientResetPasswordPost(resetPasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPatientTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return localVarFp.userPatientTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get the information of the pharmacy user by passing the email
         * @param {string} email String email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailGet: function (email, options) {
            return localVarFp.userPharmacistEmailEmailGet(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Resend registration email for pharmacy user
         * @param {string} email Email of the pharmacy user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistEmailEmailResendPost: function (email, options) {
            return localVarFp.userPharmacistEmailEmailResendPost(email, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get Pharmacist User Info
         * @param {string} id String userId of the pharmacist user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdGet: function (id, options) {
            return localVarFp.userPharmacistIdGet(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Getting pharmacist users\' information by list of user ids
         * @param {PharmacyListByIds} pharmacyListByIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdListPost: function (pharmacyListByIds, options) {
            return localVarFp.userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Edit pharmacist user details
         * @param {string} id String userId of the pharmacist user
         * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPatch: function (id, updatePharmacyUserRequestDto, options) {
            return localVarFp.userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdPrivacyAndPolicyPatch: function (id, options) {
            return localVarFp.userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Update terms and condition
         * @param {string} id String id of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistIdTermsAndConditionPatch: function (id, options) {
            return localVarFp.userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Login
         * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistLoginPost: function (pharmacistUserLoginRequest, options) {
            return localVarFp.userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Change Pharmacist User Password With Password
         * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordChangePost: function (changePasswordRequestDto, options) {
            return localVarFp.userPharmacistPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password with confirmationCode too
         * @param {ConfirmPasswordDto} [confirmPasswordDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordConfirmationPost: function (confirmPasswordDto, options) {
            return localVarFp.userPharmacistPasswordConfirmationPost(confirmPasswordDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Reset the pharmacist user\'s password
         * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordResetPost: function (resetPasswordRequestDto, options) {
            return localVarFp.userPharmacistPasswordResetPost(resetPasswordRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Set the pharmacist user\'s password
         * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistPasswordSetPost: function (setPasswordPharmacistUserRequest, options) {
            return localVarFp.userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Register the pharmacist user
         * @param {boolean} [email]
         * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistRegisterPost: function (email, registerPharmacistUserRequest, options) {
            return localVarFp.userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Generating new id token and access token using refresh token
         * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPharmacistTokenRefreshPost: function (refreshTokenRequestDto, options) {
            return localVarFp.userPharmacistTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.NoAuthApiFactory = NoAuthApiFactory;
/**
 * NoAuthApi - object-oriented interface
 * @export
 * @class NoAuthApi
 * @extends {BaseAPI}
 */
var NoAuthApi = /** @class */ (function (_super) {
    __extends(NoAuthApi, _super);
    function NoAuthApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.healthCheckGet = function (options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).healthCheckGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserAccountLocked\'
     * @param {PatientUserAccountLockedIntegrationRequestDto} [patientUserAccountLockedIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPatientAccountLockedPost = function (patientUserAccountLockedIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPatientAccountLockedPost(patientUserAccountLockedIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserEmailVerification\'
     * @param {PatientUserEmailVerificationIntegrationRequestDto} [patientUserEmailVerificationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPatientEmailVerificationPost = function (patientUserEmailVerificationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPatientEmailVerificationPost(patientUserEmailVerificationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserPasswordChanged\'
     * @param {PatientUserPasswordChangedIntegrationRequestDto} [patientUserPasswordChangedIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPatientPasswordChangedPost = function (patientUserPasswordChangedIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPatientPasswordChangedPost(patientUserPasswordChangedIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PatientUserPasswordReset\'
     * @param {PatientUserPasswordResetIntegrationRequestDto} [patientUserPasswordResetIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPatientPasswordResetPost = function (patientUserPasswordResetIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPatientPasswordResetPost(patientUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Emit the event \'PatientUserRegistration\'
     * @param {PatientUserRegistrationIntegrationRequestDto} [patientUserRegistrationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPatientRegisterPost = function (patientUserRegistrationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPatientRegisterPost(patientUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Store confirmation code history and emit the event \'PharmacyUserPasswordReset\'
     * @param {PharmacyUserPasswordResetIntegrationRequestDto} [pharmacyUserPasswordResetIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPharmacistPasswordResetPost = function (pharmacyUserPasswordResetIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPharmacistPasswordResetPost(pharmacyUserPasswordResetIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Emit the event \'PharmacyUserRegistration\'
     * @param {PharmacyUserRegistrationIntegrationRequestDto} [pharmacyUserRegistrationIntegrationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.integrationsCognitoUserPharmacistRegisterPost = function (pharmacyUserRegistrationIntegrationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).integrationsCognitoUserPharmacistRegisterPost(pharmacyUserRegistrationIntegrationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Confirm Registration - Verify Email / sms
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientAccountVerificationPost = function (body, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientAccountVerificationPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Checks if the patient exists in dp1 and dp2
     * @param {string} pharmacyId String pharmacyId of the pharmacist user
     * @param {string} email String email of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientCheckUserPharmacyIdEmailGet = function (pharmacyId, email, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientCheckUserPharmacyIdEmailGet(pharmacyId, email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change password with code from email. Confirm Reset Password with code. Not found returns a 401
     * @param {ConfirmPasswordDto} [confirmPasswordDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientConfirmPasswordPost = function (confirmPasswordDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientConfirmPasswordPost(confirmPasswordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Send notification(email/sms) when the account has been locked
     * @param {string} email Email of the patient user
     * @param {NotificationType} notificationType
     * @param {string} xPharmacyId UUID of the pharmacy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientEmailEmailLockedAccountPost = function (email, notificationType, xPharmacyId, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientEmailEmailLockedAccountPost(email, notificationType, xPharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting patient users\' information by list of user emails
     * @param {FindByEmailsDto} findByEmailsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientFindByEmailsPost = function (findByEmailsDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientFindByEmailsPost(findByEmailsDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting patient users\' information by list of user ids
     * @param {FindByIdsDto} findByIdsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientFindByIdsPost = function (findByIdsDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientFindByIdsPost(findByIdsDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {UserLogin} [userLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientLoginPost = function (userLogin, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientLoginPost(userLogin, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Google SSO Login
     * @param {UserLoginSSOBody} [userLoginSSOBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientLoginSsoPost = function (userLoginSSOBody, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientLoginSsoPost(userLoginSSOBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change Patient User Password
     * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientPasswordChangePost = function (changePasswordRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Setting the password and creating the record in database
     * @param {object} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientPasswordSetPost = function (body, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientPasswordSetPost(body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Registration - Creates a patient user, returns a userId
     * @param {UserRegisterDto} [userRegisterDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientRegisterPost = function (userRegisterDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientRegisterPost(userRegisterDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend Confirmation Code
     * @param {UserVerificationRequestDto} [userVerificationRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientResendConfirmationCodePost = function (userVerificationRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientResendConfirmationCodePost(userVerificationRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary INIT Reset Password, causes an email with code/link to be sent to user
     * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientResetPasswordPost = function (resetPasswordRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientResetPasswordPost(resetPasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Generating new id token and access token using refresh token
     * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPatientTokenRefreshPost = function (refreshTokenRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPatientTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get the information of the pharmacy user by passing the email
     * @param {string} email String email of the pharmacy user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistEmailEmailGet = function (email, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistEmailEmailGet(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Resend registration email for pharmacy user
     * @param {string} email Email of the pharmacy user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistEmailEmailResendPost = function (email, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistEmailEmailResendPost(email, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get Pharmacist User Info
     * @param {string} id String userId of the pharmacist user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdGet = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdGet(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Getting pharmacist users\' information by list of user ids
     * @param {PharmacyListByIds} pharmacyListByIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdListPost = function (pharmacyListByIds, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdListPost(pharmacyListByIds, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Edit pharmacist user details
     * @param {string} id String userId of the pharmacist user
     * @param {UpdatePharmacyUserRequestDto} [updatePharmacyUserRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdPatch = function (id, updatePharmacyUserRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdPatch(id, updatePharmacyUserRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdPrivacyAndPolicyPatch = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdPrivacyAndPolicyPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Update terms and condition
     * @param {string} id String id of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistIdTermsAndConditionPatch = function (id, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistIdTermsAndConditionPatch(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Login
     * @param {PharmacistUserLoginRequest} [pharmacistUserLoginRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistLoginPost = function (pharmacistUserLoginRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistLoginPost(pharmacistUserLoginRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Change Pharmacist User Password With Password
     * @param {ChangePasswordRequestDto} [changePasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordChangePost = function (changePasswordRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordChangePost(changePasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password with confirmationCode too
     * @param {ConfirmPasswordDto} [confirmPasswordDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordConfirmationPost = function (confirmPasswordDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordConfirmationPost(confirmPasswordDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Reset the pharmacist user\'s password
     * @param {ResetPasswordRequestDto} [resetPasswordRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordResetPost = function (resetPasswordRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordResetPost(resetPasswordRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Set the pharmacist user\'s password
     * @param {SetPasswordPharmacistUserRequest} setPasswordPharmacistUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistPasswordSetPost = function (setPasswordPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistPasswordSetPost(setPasswordPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Register the pharmacist user
     * @param {boolean} [email]
     * @param {RegisterPharmacistUserRequest} [registerPharmacistUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistRegisterPost = function (email, registerPharmacistUserRequest, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistRegisterPost(email, registerPharmacistUserRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Generating new id token and access token using refresh token
     * @param {RefreshTokenRequestDto} [refreshTokenRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoAuthApi
     */
    NoAuthApi.prototype.userPharmacistTokenRefreshPost = function (refreshTokenRequestDto, options) {
        var _this = this;
        return (0, exports.NoAuthApiFp)(this.configuration).userPharmacistTokenRefreshPost(refreshTokenRequestDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return NoAuthApi;
}(base_1.BaseAPI));
exports.NoAuthApi = NoAuthApi;
